<template>
  <div>
    <slot name="header" :issues="issues"></slot>
    <div v-if="$online">
      <v-list class="transparent pt-0" flat>
        <div class="mx-1" v-if="showFilters">
          <v-row class="px-4">
            <v-col cols="12" md="6">
              <v-text-field
                class="mb-5"
                clearable
                hide-details
                prepend-inner-icon="mdi-magnify"
                :label="$t('Search')"
                v-model="issuesSearch"
                @input="searchIssues"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div>
          <div v-for="(item, i) in issues" :key="i">
            <v-list-item-group color="primary">
              <v-list-item
                link
                :to="{ name: 'issueedit', params: { id: item.id } }"
                class="rounded mx-2 pr-3 mb-1"
                :class="{ 'grey darken-4': $vuetify.theme.dark }"
              >
                <v-list-item-content>
                  <v-list-item-title>{{
                    item.description ? item.description : formatDateTime(item.createdOn)
                  }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </div>
        </div>
      </v-list>
      <v-btn v-if="showLoadMore && !loading && issues && issues.length > 0" class="mx-3 mb-3" @click="loadMoreIssues">{{
        $t("t_load_more")
      }}</v-btn>
    </div>
    <h4 class="pa-4" v-if="(issues?.length == 0 && !loading) || !$online">
      {{ $t("No Log-its") }} {{ !$online ? " : " + $t("t_no_network_connection") : "" }}
    </h4>
  </div>
</template>
<script>
import Helpers from "@/mixins/helpers";
import debounce from "lodash/debounce";
import { IssueService } from "@/services";

export default {
  name: "IssueList",
  mixins: [Helpers],
  props: {
    takeNum: {
      type: Number,
      default: 25,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    issues: [],
    orderByField: "Id",
    ascending: true,
    skipNum: 0,
    issuesSearch: "",
    issuesTag: "",
    showLoadMore: false,
    appStateListenerAdded: false,
  }),
  methods: {
    async loadIssue(issueId) {
      const r = await IssueService.getIssue(issueId);
      this.issue = r.data;
      this.drawer = false;
    },

    async loadIssues(refresh = false, search = false) {
      this.loading = true;
      if (!this.$online) {
        return;
      }

      this.showLoadBar();

      let takeNum = this.takeNum;

      if (search) {
        this.skipNum = 0;
        takeNum = 50;
      }

      let params = {
        "paging.orderbyfield": this.orderByField,
        "paging.ascending": this.ascending,
        "paging.skipnum": this.skipNum,
        "paging.takenum": takeNum,
      };

      if (this.issuesSearch) {
        params.description = this.issuesSearch;
      }
      if (this.issuesTag) {
        params.tag = this.issuesTag;
      }
      if (refresh) {
        this.issues = [];
      }

      IssueService.getIssues(params).then((r) => {
        if (r.meta?.endOfResults) {
          this.showLoadMore = false;
        } else {
          this.showLoadMore = true;
        }
        const issues = r.data;
        issues.forEach((issue) => {
          this.issues.push(issue);
        });

        this.loading = false;
        this.hideLoadBar();
      });
    },

    loadMoreIssues() {
      this.skipNum = this.issues.length;
      this.loadIssues();
    },

    searchIssues: debounce(function () {
      this.loadIssues(true, true);
    }, 500),
  },
  mounted() {
    this.loadIssues();
    if (this.preview) {
      this.loading = true;
    }
  },
};
</script>
